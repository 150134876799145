import { CurrencyUnit } from '@utils/enums/CurrencyUnit';
import { functionUtils } from '@utils/function';
import { getProfileSelector } from '@utils/selectors';
import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const Currency = memo((props) => {
   const { data: countryData, loading: countryLoading } = useSelector(
      (state) => state.common.country
   );
   const { data: profileData, loading: profileLoading } = useSelector(getProfileSelector);
   const [value, setValue] = useState('');

   useEffect(() => {
      const { price, localPrice } = props || {};
      if (!countryLoading && !profileLoading) {
         let value = functionUtils.getCurrency(price, CurrencyUnit.USD);
         let currentCountryCode = countryData?.country;
         if (profileData?.country?.code) {
            currentCountryCode = profileData.country.code;
         }
         if (currentCountryCode === 'VN') {
            value = functionUtils.getCurrency(localPrice, CurrencyUnit.VND);
         }
         setValue(value);
      }
   }, [countryLoading, profileLoading, profileData, countryData, props]);

   return value;
});

export default Currency;
