import React from 'react';

const IconDelete = () => (
   <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <circle cx={8} cy={8} r="7.5" stroke="currentColor" />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M8.00004 7.29293L10.6465 4.64648L11.3536 5.35359L8.70714 8.00004L11.3536 10.6465L10.6465 11.3536L8.00004 8.70714L5.35359 11.3536L4.64648 10.6465L7.29293 8.00004L4.64648 5.35359L5.35359 4.64648L8.00004 7.29293Z"
         fill="currentColor"
      />
   </svg>
);

export default IconDelete;