import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import constant from '../../../utils/constant';
import { createCancelToken } from '../../../apis/ServiceController';
import RequestModel from '../../../apis/models/RequestModel';
import { productApi } from '../../../apis/productApi';
import ContentLoading from '../../../views_admin/ContentLoading';
import CommonTable from '../../../views_admin/components/CommonTable';
import { Link } from 'gatsby';
import IconAdd2 from '../../../components/Icons/IconAdd2';
import Search from '../../../views_admin/layout/Search';
import { ProductStatus } from '@utils/enums/ProductStatus';
import _capitalize from 'lodash/capitalize';
import _lowerCase from 'lodash/lowerCase';
import { functionUtils } from '@utils/function';
import { convertToDate } from '@utils/common';
import { Tooltip } from 'antd';
import IconEdit from '@icons/IconEdit';
import BtnDelete from '@views_admin/products/BtnDelete';
import BtnRestore from '@views_admin/products/BtnRestore';
import Sort from '@views_admin/components/Sort';
import Filter from '@views_admin/products/Filter';
import Currency from '@components/Currency';
import { SortType } from '@apis/enums/SortType';
import SEO from '@components/SEO';

const sortOptions = [
   {
      label: 'Last modified',
      value: 'updated_at',
   },
   {
      label: 'Date created',
      value: 'created_at',
   },
   {
      label: 'Name',
      value: 'name',
   },
   {
      label: 'Status',
      value: 'status',
   },
   {
      label: 'Price',
      value: 'price',
   },
   {
      label: 'Weight',
      value: 'weight',
   },
];

const Products = memo(() => {
   const [searchText, setSearchText] = useState('');
   const [filter, setFilter] = useState({
      page: 1,
      pageSize: constant.defaultPagesize,
      orderBy: 'updated_at',
      sortType: SortType.DESC,
      status: '',
      fromPrice: '',
      toPrice: '',
      fromDate: '',
      toDate: '',
   });
   const [data, setData] = useState({
      content: [],
      totalRows: 0,
   });
   const [loading, setLoading] = useState(true);
   const cancelToken = useRef();

   const reloadData = useCallback(() => {
      setFilter((prevFilter) => ({
         ...prevFilter,
         // page: 1,
      }));
   }, []);

   const columns = useMemo(() => {
      return [
         {
            title: 'Name',
            dataIndex: 'name',
            render: (text, record) => (
               <>
                  {record.status !== ProductStatus.REMOVED ? (
                     <Link
                        className="product-url description-col-content"
                        to={`${constant.ROUTE_ADMIN_PRODUCTS}/${record.uid}`}
                     >
                        {text}
                     </Link>
                  ) : (
                     <span
                        style={{ cursor: 'default' }}
                        className="description-col-content"
                     >
                        {text}
                     </span>
                  )}
               </>
            ),
         },
         {
            title: 'Code',
            dataIndex: 'code',
         },
         {
            title: 'Quantity',
            dataIndex: 'quantity',
         },
         {
            title: 'Status',
            dataIndex: 'status',
            render: (text, record) => (
               <span className={`product-stt ${text}`}>
                  {(() => {
                     if (text === ProductStatus.SOLD) {
                        return 'Out of stock';
                     }
                     return _capitalize(_lowerCase(text));
                  })()}
               </span>
            ),
         },
         {
            title: 'Weight',
            dataIndex: 'weight',
            render: (text, record) => (
               <span>{`${text} ${_lowerCase(record.weight_unit)}`}</span>
            ),
         },
         {
            title: 'Price',
            dataIndex: 'price',
            render: (text, record) => (
               <span>
                  <Currency price={record.price} localPrice={record.local_price} />
               </span>
            ),
         },
         {
            title: 'Created date',
            dataIndex: 'created_at',
            render: (text, record) => <span>{convertToDate(text)}</span>,
         },
         {
            title: 'Action',
            key: 'action',
            width: 116,
            render: (text, record) => (
               <div className="admin-col-action">
                  {record.status !== ProductStatus.REMOVED && (
                     <Tooltip placement="bottom" title="Edit" color="black">
                        <Link
                           className="button-icon action-normal"
                           to={`${constant.ROUTE_ADMIN_PRODUCTS}/${record.uid}`}
                        >
                           <IconEdit />
                        </Link>
                     </Tooltip>
                  )}
                  {record.status === ProductStatus.REMOVED ? (
                     <BtnRestore uid={record.uid} onSuccess={reloadData} />
                  ) : (
                     <BtnDelete
                        uid={record.uid}
                        name={record.name}
                        onSuccess={reloadData}
                     />
                  )}
               </div>
            ),
         },
      ];
   }, [reloadData]);

   useEffect(() => {
      if (cancelToken.current) {
         cancelToken.current.cancel();
      }
      setLoading(true);
      cancelToken.current = createCancelToken();
      const loadProducts = async () => {
         const requestBody = new RequestModel([
            filter,
            {
               search: searchText.trim(),
            },
         ]);
         const responseData = await productApi.getProductsMatrix(
            requestBody,
            cancelToken.current?.token
         );
         console.log(responseData);
         if (responseData.isSuccess) {
            const { content, totalRows } = responseData.data;
            setData((prevData) => ({
               ...prevData,
               content: content || [],
               totalRows: totalRows,
            }));
         }
         setSearchText(searchText.trim());
         setLoading(false);
      };
      loadProducts();

      return () => {
         if (cancelToken.current) {
            cancelToken.current.cancel();
         }
      };
   }, [filter, searchText]);

   const onTableChange = useCallback(({ pagination, sorter }) => {
      setFilter((prevFilter) => ({
         ...prevFilter,
         page: pagination?.page || prevFilter.page,
         pageSize: pagination?.pageSize || prevFilter.pageSize,
      }));
   }, []);

   const handleSort = useCallback((orderBy, sortType) => {
      setFilter((prevFilter) => ({
         ...prevFilter,
         sortType,
         orderBy,
      }));
   }, []);

   const handleFilter = useCallback((newFilter = {}) => {
      setFilter((prevFilter) => ({
         ...prevFilter,
         ...newFilter,
         page: 1,
      }));
   }, []);

   const filterSet = useMemo(() => {
      return {
         status: filter.status,
         fromPrice: filter.fromPrice,
         toPrice: filter.toPrice,
         fromDate: filter.fromDate,
         toDate: filter.toDate,
      };
   }, [filter]);

   return (
      <>
         <SEO title={'Products - Admin'} />
         <div className="content-head">
            <div className="content-head__left">
               <label className="title">Products</label>
            </div>
            <div className="content-head__right">
               <Link
                  to={constant.ROUTE_ADMIN_NEW_PRODUCT}
                  className="outline-btn btn-add-new-item"
               >
                  <i>
                     <IconAdd2 />
                  </i>
                  Add Product
               </Link>
            </div>
         </div>
         <div className="content-body admin-products__body">
            <div className="admin-table">
               <ContentLoading isLoading={loading} />
               <div className="admin-table__header">
                  <div className="admin-table__header--left">
                     <Search
                        placeholder="Search in products"
                        onSearch={(v) => setSearchText(v)}
                     />
                  </div>
                  <div className="admin-table__header--right">
                     <Filter filterSet={filterSet} onChange={handleFilter} />
                     <Sort
                        orderBy={filter.orderBy}
                        sortType={filter.sortType}
                        options={sortOptions}
                        onChange={handleSort}
                     />
                  </div>
               </div>
               <CommonTable
                  rowKey="uid"
                  unit="Products"
                  dataSource={data.content}
                  columns={columns}
                  page={filter.page}
                  pageSize={filter.pageSize}
                  totalRows={data.totalRows}
                  onChange={onTableChange}
               />
            </div>
         </div>
      </>
   );
});

export default Products;
