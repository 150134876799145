import { adminService } from '../services/adminService';
import { tokenUtil } from '../utils/token';
import { apiConstant } from './constant';
import { fetch } from './ServiceController';
import _forIn from 'lodash/forIn';

const getProductsMatrix = async (body, cancelToken) => {
   const token = tokenUtil.getToken();
   let headers = {
      'Content-Type': 'application/json',
   };
   if (token) {
      headers = {
         ...headers,
         Authorization: token,
      };
   }

   return fetch({
      method: 'post',
      url: apiConstant.GET_PRODUCTS_MATRIX,
      data: body,
      headers,
      cancelToken,
   });
};

const createProduct = async (body, cancelToken) => {
   return fetch({
      method: 'post',
      url: apiConstant.CREATE_PRODUCT,
      data: body,
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
      errorHandler: adminService.handleError,
   });
};

const updateProduct = async (uid, body, cancelToken) => {
   return fetch({
      method: 'put',
      url: apiConstant.UPDATE_PRODUCT,
      data: body,
      params: {
         uid,
      },
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
      errorHandler: adminService.handleError,
   });
};

const deleteProduct = async (uid, cancelToken) => {
   return fetch({
      method: 'delete',
      url: apiConstant.DELETE_PRODUCT,
      params: {
         uid,
      },
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
      errorHandler: adminService.handleError,
   });
};

const recoverProduct = async (uid, cancelToken) => {
   return fetch({
      method: 'put',
      url: apiConstant.RECOVER_PRODUCT,
      params: {
         uid,
      },
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
      errorHandler: adminService.handleError,
   });
};

const getProductByUid = async (uid, slug, cancelToken) => {
   const params = {};
   if (uid) params.uid = uid;
   if (slug) params.slug = slug;
   return fetch({
      method: 'get',
      url: apiConstant.GET_PRODUCT_DETAILS,
      params,
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
      errorHandler: adminService.handleError,
   });
};

const updateProductStatus = async (body, cancelToken) => {
   return fetch({
      method: 'put',
      url: apiConstant.UPDATE_PRODUCT_STATUSES,
      data: body,
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
      errorHandler: adminService.handleError,
   });
};

export const productApi = {
   getProductsMatrix,
   createProduct,
   updateProduct,
   deleteProduct,
   recoverProduct,
   getProductByUid,
   updateProductStatus,
};
