import { productApi } from '@apis/productApi';
import { message } from '@components/Message';
import IconRestore from '@icons/IconRestore';
import { Tooltip } from 'antd';
import React, { memo } from 'react';

const BtnRestore = memo(({ uid, onSuccess }) => {

   const restore = async () => {
      if (uid) {
         message.loading('Recovering product...', 0);
         const responseData = await productApi.recoverProduct(uid);
         setTimeout(async () => {
            if (responseData.isSuccess) {
               if (onSuccess) {
                  onSuccess();
               }
               message.success('Recover product successfully.');
            } else {
               message.error(responseData.message);
            }
         }, 500);
      }
   }

   return (
      <Tooltip placement="bottom" title="Recover" color="black">
         <button className={`button-icon`} onClick={restore}>
            <IconRestore />
         </button>
      </Tooltip>
   );
});

export default BtnRestore;