import React from 'react';

const IconRestore = () => (
   <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M10 8C10 9.10457 9.10457 10 8 10C6.89543 10 6 9.10457 6 8C6 6.89543 6.89543 6 8 6C9.10457 6 10 6.89543 10 8Z"
         fill="#FF8C4B"
      />
      <path d="M5 5L2.62268e-07 7L0 1L5 5Z" fill="#FF8C4B" />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M2.57551 3.57551C3.85914 2.00359 5.81245 1 8.00016 1C11.8662 1 15.0002 4.13401 15.0002 8C15.0002 11.866 11.8662 15 8.00016 15C4.82914 15 2.15059 12.8915 1.29004 10H0.252199C1.14028 13.4505 4.27249 16 8.00016 16C12.4184 16 16.0002 12.4183 16.0002 8C16.0002 3.58172 12.4184 0 8.00016 0C5.53639 0 3.33275 1.11375 1.86524 2.86524L2.57551 3.57551Z"
         fill="#FF8C4B"
      />
   </svg>
);

export default IconRestore;