import React, { memo, useCallback, useState } from "react";
import IconDelete from "../../components/Icons/IconDelete";
import { Tooltip } from 'antd';
import { message } from "../../components/Common/Message";
import PopConfirm from '@components/PopConfirm';
import { productApi } from "@apis/productApi";

const BtnDelete = memo(({ uid, name, onSuccess }) => {
   const [isModalVisible, setIsModalVisible] = useState(false);

   const showModal = useCallback(() => {
      setIsModalVisible(true);
   },
      []
   )

   const handleOk = useCallback(async () => {
      if (uid) {
         setIsModalVisible(false);
         message.loading('Removing...', 0);
         const responseData = await productApi.deleteProduct(uid);
         setTimeout(async () => {
            if (responseData.isSuccess) {
               if (onSuccess) {
                  onSuccess();
               }
               message.success('Remove product successfully.');
            } else {
               message.error(responseData.message);
            }
         }, 500);
      }
   },
      [uid, onSuccess]
   )

   const onPopConfirmVisibleChange = useCallback((isOpen) => {
      setIsModalVisible(isOpen);
   }, [])

   return (
      <>
         <PopConfirm
            title="Are you sure to remove this product?"
            isOpen={isModalVisible}
            okText="Remove"
            zIndex={4039}
            onVisibleChange={onPopConfirmVisibleChange}
            onConfirm={handleOk}
         >
            <Tooltip placement="bottom" title="Remove" color="black">
               <button className={`button-icon action-delete ${isModalVisible ? 'active' : ''}`} onClick={showModal}>
                  <IconDelete />
               </button>
            </Tooltip>
         </PopConfirm>
      </>
   )
})

export default BtnDelete;